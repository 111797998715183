/// <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
import {Aurelia, ViewLocator, Origin} from 'aurelia-framework'
import environment from './environment';
import {PLATFORM} from 'aurelia-pal';
import {AppConfig} from 'appconfig';
import 'app';

export function configure(aurelia: Aurelia) {
		aurelia.use
			.standardConfiguration()
		//	.feature(PLATFORM.moduleName('resources/index'))

		// Uncomment the line below to enable animation.
		// aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
		// if the css animator is enabled, add swap-order="after" to all router-view elements

		// Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
		// aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

		ViewLocator.prototype.convertOriginToViewUrl = ({moduleId}: Origin): string => /^elements\/\w+$/.test(moduleId) ? `${moduleId}/index.html` : `${moduleId}.html`;

		if (environment.debug) {
			aurelia.use.developmentLogging();
		}

		if (environment.testing) {
			aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
		}

	AppConfig.load().then(() => {
		aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
	});
}
