import {RouteConfig} from "aurelia-router";

export class Route {
	public readonly name: string;
	public readonly route: string;

	constructor(
		public readonly module: string,
		public readonly title: string,
		public readonly isVisible: boolean,
		public readonly isItem: boolean = false,
		public readonly baseRoute?: string,
		appendRoute?: string
	) {
		this.name = this.module.replace('views/', '');
		if (this.baseRoute === undefined) this.baseRoute = this.name;
		this.route = this.baseRoute;
		if (this.isItem) this.route += '/:id';
		if (appendRoute) this.route += appendRoute;
	}

	public get config(): RouteConfig {
		return {
			route: this.route,
			name: this.name,
			moduleId: this.module,
			nav: this.isVisible,
			title: this.title,
			settings: {route: this}
		};
	}
}
