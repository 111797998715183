/**
 * Gets a property of on object by parsing a dot-notation path
 * @param {Object} object The object
 * @param {string|string[]} path The path of the property to retrieve
 * @returns {*} The property
 */
export function getProperty(object: Object, path: string|string[]): any {
	return (typeof path === 'string' ? path.split('.') : path).reduce((parent, name) => parent && parent[name], object);
}

/**
 * Gets a subset of an object's properties
 * @param {Object} object The object
 * @param {...string[]} properties The properties to return
 * @returns {Object} A new object with the subset of properties
 */
export function getSubset(object: Object, ...properties: string[]): Object {
	return Object.assign({}, ...properties.map((property) => ({[property]: object[property]})));
}

/**
 * Escapes a string for use in a regular expression
 * @param {String} str The string to escape
 * @returns {String} The escaped string
 */
export function escapeRegExp(str: string): string {
	return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

/**
 * Encodes HTML entities. Pulled and modified from ngSanitize source code
 * @param {String} str The string to encode
 * @returns {String} Encoded string
 */
export function encodeEntities(str: string): string {
	return str.
		replace(/&/g, '&amp;').
		replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, function(value) {
			return '&#' + (((value.charCodeAt(0) - 0xD800) * 0x400) + (value.charCodeAt(1) - 0xDC00) + 0x10000) + ';';
		}).
		replace(/([^\#-~ |!])/g, function (value) {
			return '&#' + value.charCodeAt(0) + ';';
		}).
		replace(/</g, '&lt;').
		replace(/>/g, '&gt;');
}

/**
 * Capitalizes the first letter of a string, or the first letter of each word in a string
 * @param {string} str The original string
 * @param {boolean} [allWords=false] Whether to capitalize each word
 * @returns {string} The capitalized string
 */
export function capitalize(str: string, allWords: boolean = false): string {
	if (!allWords) return str.substring(0, 1).toUpperCase() + str.substring(1);
	return str.replace(/(^|\s)(\w)/g, (match, space, letter) => space + letter.toUpperCase());
}

/**
 * Pads a number with zeros, out to the specified number of digits
 * @param {number} num The number to pad
 * @param {number} [digits=2] The minimum number of digits
 * @returns {string} The padded number
 */
export function padNumber(num: number, digits: number = 2): string {
	let str = num.toString();
	while (str.length < digits) str = '0' + str;
	return str;
}

/**
 * Copies a string to the clipboard
 * @param {string} value The string to copy
 * @returns {void}
 */
export function copyValue(value: string): void {
	const clipboard = (window as any).clipboardData;
	if (clipboard && clipboard.setData) return clipboard.setData("Text", value);
	if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
		const body = document.getElementsByTagName('body')[0];
		const element = document.createElement('textarea');
		element.className = 'clipboard';
		element.value = value;
		body.append(element);
		element.select();
		document.execCommand("copy");
		element.remove();
	}
}
