export class Price {
	private value: number = 0;

	static fromDollars(value?: Price|number|string): Price {
		if (value instanceof Price) return value;
		const price = new Price();
		if (typeof value === 'string') value = parseFloat(value);
		price.dollars = value;
		return price;
	}
	static fromCents(value?: Price|number): Price {
		if (value instanceof Price) return value;
		return new Price(value);
	}

	constructor(value?: number|string) {
		if (!value) return;
		if (typeof value === 'number') {
			this.cents = value;
		} else if (typeof value === 'string') {
			this.dollars = parseFloat(value);
		}
	}
	toString(): string {
		return this.dollars.toFixed(2);
	}
	valueOf(): number {
		return this.cents;
	}
	set dollars(value: number) {
		if (typeof value !== 'number' || Number.isNaN(value)) throw new Error('Cannot assign non-numeric value to dollars');
		this.cents = value * 100;
	}
	get dollars(): number {
		return this.cents / 100;
	}
	set cents(value: number) {
		if (typeof value !== 'number' || Number.isNaN(value)) throw new Error('Cannot assign non-numeric value to cents');
		this.value = value;
	}
	get cents(): number {
		return Math.round(this.value);
	}
	get split(): string[] {
		return this.toString().split('.');
	}
}
