import {WebService, WebServiceResponse} from "webservice";
import {DropdownConfig, DropdownOption, Dropdown} from "elements/dropdown";
import {UploadConfig, Upload, UploadData} from "elements/upload";
import {observable} from "aurelia-binding";
import {Price} from "price";
import {ItemEditorSaveResponse} from "elements/itemeditor";
import {RichText} from "elements/richtext";
import {ListEditor, ListEditorValue, ListEditorConfig} from "elements/listeditor";
import {DatePicker} from "elements/datepicker";
import {StaticItemTableConfig, StaticItemTable} from "elements/itemtable/static";
import {AddressData, Address} from "elements/address";

export class Form {
	private readonly defaultError: string = 'Please correct the errors in red below.';
	public readonly fields: Map<string, Field> = new Map();
	public method: 'get'|'post' = 'post';
	public error: string = '';
	public isValid: boolean = true;
	public isLoading: boolean = false;
	public onSuccess?: (data: WebServiceResponse) => any;
	public onError?: (error: Error|string) => any;

	constructor(public readonly id: string, public readonly webService: string, fields?: Field[], public title?: string) {
		if (fields) this.addFields(fields);
	}
	public addFields(fields: Field[]): Form {
		for (const field of fields) {
			field.setForm(this);
			this.fields.set(field.id, field);
		}
		return this;
	}
	public validate(): boolean {
		this.error = '';
		this.isValid = true;
		this.fields.forEach((field) => {
			if (!field.validate()) {
				this.error = this.defaultError;
				this.isValid = false;
			}
		});
		return this.isValid;
	}
	public submit(): void {
		if (!this.validate()) return;
		WebService[this.method](this.webService, this.values).then((data) => {
			if (this.onSuccess) this.onSuccess(data);
		}, (e) => {
			this.isValid = false;
			if (e instanceof Error) {
				this.error = e.message;
			} else if (typeof e === 'string') {
				this.error = e;
			} else if (e) {
				this.error = this.defaultError;
				for (const id in e) {
					if (this.fields.has(id)) this.fields.get(id).flag(e[id]);
				}
			}
		});
	}
	public get values(): {[index: string]: any} {
		const values = {};
		this.fields.forEach((field) => {
			values[field.id] = field.value;
		});
		return values;
	}
}

export abstract class Field {
	public abstract value: any;
	public containerElement: HTMLElement;
	public element: HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement|HTMLDivElement;
	public form: Form;
	public htmlId: string;
	public isValid: boolean = true;
	public error: string = '';
	public isInEditor: boolean = false;
	public cssClass?: string;
	public watchChanges?: () => void;

	constructor(
		public readonly tag: string,
		public readonly id: string,
		public label?: string,
		public readonly isRequired: boolean = false
	) {
	}

	public attached(): void {
		if (this.cssClass) this.containerElement.classList.add(...this.cssClass.split(' ').filter((item) => !!item));
		if (this.watchChanges) this.watchChanges();
	}

	public setForm(form: Form): void {
		this.form = form;
		this.htmlId = `field-${this.form.id}-${this.id}`;
	}

	public validate(): boolean {
		this.flag(this.isRequired && !this.value && 'Required');
		return this.isValid;
	}

	public flag(error?: string|false): void {
		this.isValid = !error;
		this.error = error || '';
		this.containerElement.classList[this.isValid ? 'remove' : 'add']('error');
	}
}

export abstract class CustomField extends Field {
	public onChange?(): void;
}


export class StaticListItem {
	private href?: string;
	private callback?: () => void;

	constructor(
		public value: string,
		public link?: string|(() => void),
		public linkTarget: string = '_blank'
	) {
		if (!link) return;
		if (typeof link === 'string') {
			this.href = link;
			this.callback = null;
		} else {
			this.href = null;
			this.callback = link;
		}
	}
}

export class StaticField extends Field {
	public value: string|any[];
	public linkTarget: string = '_blank';
	public items: (StaticField|StaticListItem)[] = [this];
	private href?: string;
	private callback?: () => void;
	private _link?: string|(() => void);

	constructor(id: string, label: string) {
		super('div', id, label, false);
	}

	public set link(link: string|(() => void)) {
		this._link = link;
		if (typeof link === 'string') {
			this.href = link;
			this.callback = null;
		} else {
			this.href = null;
			this.callback = link;
		}
	}

	public get link(): string|(() => void) {
		return this._link;
	}

	public validate(): boolean {
		this.flag();
		return true;
	}
}

export class StaticAddressField extends Field {
	public value: AddressData|string;
	public address: Address;

	constructor(id: string, label: string) {
		super('address', id, label, false);
	}

	public validate(): boolean {
		this.flag();
		return true;
	}
}

export class StaticItemTableField extends CustomField {
	@observable public value: any;
	public table: StaticItemTable;
	public cssClass = 'static-table';

	constructor(id: string, public readonly config: StaticItemTableConfig) {
		super('table', id, undefined, false);
	}

	public validate(): boolean {
		this.flag();
		return true;
	}

	private valueChanged(): void {
		if (this.onChange) this.onChange();
	}
}

abstract class TextField extends Field {
	public hasAutoFocus: boolean = false;
	public hasPlaceholder: boolean|string = false;

	public autoFocus(hasAutoFocus: boolean = true): TextField {
		this.hasAutoFocus = hasAutoFocus;
		return this;
	}

	public get placeholder(): string {
		if (!this.hasPlaceholder) return '';
		if (typeof this.hasPlaceholder === 'string') return this.hasPlaceholder;
		return this.isRequired ? 'Required' : 'None';
	}
}

export class InputField extends TextField {
	@observable public displayValue: string;
	@observable public value: string;

	constructor(id: string, label: string, isRequired?: boolean, public readonly type: string = 'text') {
		super('input', id, label, isRequired);
	}

	private displayValueChanged(value: string): void {
		this.value = value;
	}

	private valueChanged(value: string): void {
		this.displayValue = value;
	}
}

export class NumericInputField extends TextField {
	@observable public displayValue: string;
	public readonly type: string = 'tel';

	constructor(id: string, label: string, isRequired?: boolean) {
		super('input', id, label, isRequired);
	}

	public validate(): boolean {
		if (super.validate() && this.value !== null && Number.isNaN(this.value)) {
			this.flag('Invalid input');
		}
		return this.isValid;
	}

	public get value(): number {
		return this.displayValue ? parseFloat(this.displayValue) : null;
	}
	public set value(value: number) {
		this.displayValue = typeof value === 'number' ? value.toString() : null;
	}
}

export class TelInputField extends InputField {
	constructor(id: string, label: string, isRequired?: boolean) {
		super(id, label, isRequired, 'tel');
	}

	public attached(): void {
		super.attached();
		this.element.addEventListener('blur', (event: FocusEvent) => {
			this.value = this.displayValue.replace(/[^\d\+ex]/g, '').replace(
				/^(?:\+?1)?(\d{3})(\d{3})(\d{4})(?:(?:ex|e|x)(\d+))?$/i,
				(m, area, prefix, num, ext) => `(${area}) ${prefix}-${num}${ext ? ` x${ext}`: ''}`
			);
		});
	}
}

export class EmailInputField extends InputField {
	constructor(id: string, label: string, isRequired?: boolean) {
		super(id, label, isRequired, 'email');
	}

	public validate(): boolean {
		if (super.validate() && this.value) {
			this.value = this.value.trim();
			this.flag(!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.value) && 'Invalid email');
		}
		return this.isValid;
	}
}

export class TextAreaField extends TextField {
	@observable public value: string;
	public autoSize: boolean = true;
	private shadowContainer?: HTMLDivElement;
	private shadow?: HTMLTextAreaElement;

	constructor(id: string, label: string, isRequired?: boolean) {
		super('textarea', id, label, isRequired);
	}

	public attached(): void {
		super.attached();
		if (this.autoSize) {
			this.shadow = document.createElement('textarea');
			this.shadow.disabled = true;
			this.shadowContainer = document.createElement('div');
			this.shadowContainer.className = 'autosize-shadow';
			this.shadowContainer.append(this.shadow);
			this.element.parentElement.prepend(this.shadowContainer);
		}
		setTimeout(() => this.valueChanged(this.value), 0);
	}

	private valueChanged(value: string): void {
		if (!this.shadow) return;
		this.shadow.value = value;
		this.shadow.scrollTop = 999999;
		this.element.style.height = (this.shadow.clientHeight + this.shadow.scrollTop) + 'px';
	}
}

export class RichTextField extends TextField {
	public originalValue?: string;
	public richText?: RichText;
	public onChange?: () => void;
	public readonly cssClass: string = 'upload';

	constructor(id: string, label: string, isRequired?: boolean) {
		super('richtext', id, label, isRequired);
	}

	public saved(response: ItemEditorSaveResponse): void {
		if (this.richText) this.richText.saved(response);
	}

	public get value(): string {
		return this.richText && this.richText.value;
	}

	public set value(value: string) {
		this.originalValue = value;
		if (this.richText) this.richText.value = value;
	}
}

export class DateField extends TextField {
	public originalValue: string = null;
	public datePicker?: DatePicker;
	public onChange?: () => void;

	constructor(id: string, label: string, isRequired?: boolean) {
		super('date', id, label, isRequired);
	}

	public get value(): string {
		return this.datePicker && this.datePicker.value;
	}

	public set value(value: string) {
		this.originalValue = value;
		if (this.datePicker) this.datePicker.value = value;
	}
}

export class PriceField extends TextField {
	@observable public displayValue: string;
	public readonly type: string = 'text'
	public readonly cssClass: string = 'price';
	private price: Price = new Price();

	constructor(id: string, label: string, isRequired?: boolean) {
		super('input', id, label, isRequired);
	}

	public attached(): void {
		super.attached();
		this.element.addEventListener('blur', () => {
			this.displayValue = this.price.toString();
		});
	}

	private displayValueChanged(value: string, originalValue: string): void {
		this.price.dollars = value && parseFloat(value) || 0;
	}

	public get value(): number {
		return this.price.cents;
	}
	public set value(value: number) {
		this.price.cents = value || 0;
		this.displayValue = this.price.toString();
	}
}

export class BooleanField extends CustomField {
	public value: boolean;

	constructor(id: string, label: string, defaultValue: boolean = false, private readonly invert: boolean = false) {
		super('switch', id, label);
		this.value = defaultValue;
	}

	public toggle(): void {
		this.value = !this.value;
		if (this.onChange) this.onChange();
	}

	private onKey({key}: KeyboardEvent): boolean {
		if (key !== 'Enter' && key !== ' ') return true;
		this.toggle();
	}
}

export class CustomActionField<T, DT = T> extends CustomField {
	@observable public value: T;
	public displayValue: DT;

	constructor(tag: string, id: string, label: string, public readonly defaultValue: T, public readonly defaultDisplayValue: DT, private onClick: () => void) {
		super(tag, id, label);
		this.value = defaultValue;
		this.displayValue = defaultDisplayValue;
	}

	private valueChanged(): void {
		if (this.onChange) this.onChange();
	}
}

export class AddressField extends CustomActionField<AddressData, AddressData|string> {
	public address: Address;
}

export class DropdownField extends CustomField {
	private readonly config: DropdownConfig;
	private dropdown: Dropdown;
	private originalValue: string;

	constructor(id: string, label: string, options: DropdownOption[], isRequired?: boolean, isSearchable: boolean = false) {
		super('dropdown', id, label, isRequired);
		this.config = new DropdownConfig(options, {
			isSearchable: isSearchable,
			onChange: () => {
				if (this.onChange) this.onChange();
			},
			onCreate: (dropdown) => {
				this.dropdown = dropdown;
				if (this.originalValue) this.dropdown.selectOptionData(this.originalValue);
				if (this.onChange) this.onChange();
			}
		});
	}

	public get value(): string {
		return this.dropdown && this.dropdown.selectedOption.data;
	}

	public set value(value: string) {
		this.originalValue = value;
		if (this.dropdown) this.dropdown.selectOptionData(value);
	}
}

export class ListEditorField extends CustomField {
	public readonly ListEditor: typeof ListEditor;
	public readonly cssClass = 'list-editor';
	public listEditor: ListEditor;
	public originalValue?: ListEditorValue;

	constructor(
		id: string,
		label: string,
		public readonly webService: string,
		public readonly config: ListEditorConfig
	) {
		super('listeditor', id, label);
	}

	public saved(response: ItemEditorSaveResponse): void {
		if (this.listEditor) this.listEditor.saved(response);
	}

	public get value(): ListEditorValue {
		return this.listEditor && this.listEditor.value;
	}

	public set value(value: ListEditorValue) {
		this.originalValue = value;
		if (this.listEditor) this.listEditor.value = value;
	}
}

export class UploadField extends CustomField {
	private upload: Upload;
	private originalImage: string;
	public readonly originalValue: undefined = undefined;
	public readonly config: UploadConfig;
	public readonly cssClass: string = 'upload';

	constructor(id: string, label: string, params?: any, thumbSize: number = 300) {
		super('upload', id, label);
		this.config = {
			params: params,
			thumbSize: thumbSize,
			onChange: () => {
				if (this.onChange) this.onChange();
			},
			onCreate: (upload: Upload) => {
				upload.field = this;
				this.upload = upload;
				if (this.originalImage) this.upload.image = this.originalImage;
			}
		};
	}

	public updateConfig(config: UploadConfig) {
		Object.assign(this.config, config);
	}

	public reset(): void {
		if (this.upload) this.upload.reset();
	}

	public saved(response: ItemEditorSaveResponse): void {
		if (this.upload) this.upload.saved(response);
	}

	public get value(): UploadData {
		return this.upload && this.upload.value;
	}

	public set value(value: UploadData) {
		if (this.upload) this.upload.value = value;
	}

	public set image(value: string) {
		this.originalImage = value;
		if (this.upload) {
			this.upload.image = this.originalImage;
		}
	}

	public get image(): string {
		return this.upload && this.upload.image;
	}
}
